<script>
import Layout from '../../layouts/main';
import { togglePasswordVisibility } from '@/mixins/form/toggle-password-visibility';
import PageHeader from '@/components/page-header';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { validationMixin } from '@/mixins/form/validation';
import Multiselect from '@vueform/multiselect';

export default {
  mixins: [validationMixin, togglePasswordVisibility],
  components: {
    Layout,
    PageHeader,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  data() {
    return {
      title: this.$t('OpenAccount.Title'),
      loading: true,
      form: {
        name: '',
        leverage: 100,
        currency_id: 1,
        is_demo: false,
        amount: null,
        password: '',
        password_confirmation: '',
      },
      leverageList: [],
      cyrrenciesList: [],
      passwordFields: {
        password: 'password',
        password_confirmation: 'password',
      },
    };
  },
  methods: {
    onSubmit(values, actions) {
      this.loading = true;

      this.axios
        .post(`/accounts`, this.form)
        .then(() => {
          this.$store.dispatch('accounts/getAccounts', true);
          this.$router.push({ name: 'accounts' });
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });
          this.loading = false;
        });
    },
    loadLeverage() {
      return this.axios.get(`/accounts/leverage`).then(({ data }) => {
        for (const key in data) {
          this.leverageList.push({
            value: data[key],
            text: key,
          });
        }
      });
    },
    loadCurrencies() {
      return this.axios.get(`/currencies`).then(({ data }) => {
        this.cyrrenciesList = data.map((currency) => {
          return {
            value: currency.id,
            text: currency.key,
          };
        });
      });
    },
  },
  mounted() {
    Promise.all([this.loadCurrencies(), this.loadLeverage()]).then(() => {
      this.loading = false;
    });
  },
  metaInfo() {
    return {
      title: this.$t('OpenAccount.Title'),
    };
  },
};
</script>

<template>
  <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
    <Layout>
      <PageHeader :title="title" />
      <div class="card card-transparent mb-4">
        <div class="row">
          <div class="col-8 col-sm-9 col-12">
            <div class="bg-white">
              <div class="form-title bordered">
                <div class="row justify-content-center">
                  <div class="col-xxl-6 col-xl-8 col-lg-10">
                    {{ $t('OpenAccount.NewAccount') }}
                  </div>
                </div>
              </div>

              <div class="row pt-3 pb-3 justify-content-center">
                <div class="col-xxl-6 col-xl-8 col-lg-10">
                  <Form @submit="onSubmit">
                    <div class="type-select">
                      <div
                        class="type-select-item text-success real"
                        :class="{ active: !form.is_demo }"
                        @click="form.is_demo = false"
                      >
                        <i class="bx bx-wink-smile font-size-18 align-middle me-2"></i>
                        {{ $t('Transaction.Real') }}
                      </div>

                      <div
                        class="type-select-item text-primary demo"
                        :class="{ active: form.is_demo }"
                        @click="form.is_demo = true"
                      >
                        <i class="bx bx-confused font-size-18 align-middle me-2"></i>
                        {{ $t('Transaction.Demo') }}
                      </div>
                    </div>

                    <Field
                      name="name"
                      rules="required"
                      :label="$t('OpenAccount.NameAccount')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3" :label="$t('OpenAccount.NameAccount')">
                        <b-form-input
                          v-model="form.name"
                          type="text"
                          :placeholder="$t('OpenAccount.Name')"
                          :class="{ 'is-invalid': errors.length }"
                          v-bind="field"
                        />

                        <ErrorMessage name="name" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field name="leverage" :label="$t('OpenAccount.Leverage')" v-slot="{ errors, field }">
                      <b-form-group class="mb-3" :label="$t('OpenAccount.Leverage')">
                        <Multiselect
                          v-model="form.leverage"
                          :key="leverageList.length"
                          :options="leverageList"
                          :rtl="$i18n.locale === 'ar'"
                          label="text"
                          :class="{ 'is-invalid': errors.length }"
                          :can-deselect="false"
                          :can-clear="false"
                          v-bind="field"
                        />

                        <ErrorMessage name="leverage" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field name="currency_id" :label="$t('OpenAccount.Currency')" v-slot="{ errors, field }">
                      <b-form-group class="mb-3" :label="$t('OpenAccount.Currency')">
                        <Multiselect
                          v-model="form.currency_id"
                          :key="cyrrenciesList.length"
                          :options="cyrrenciesList"
                          :label="'text'"
                          :rtl="$i18n.locale === 'ar'"
                          :class="{ 'is-invalid': errors.length }"
                          :can-deselect="false"
                          :can-clear="false"
                          v-bind="field"
                        />

                        <ErrorMessage name="currency_id" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      v-if="form.is_demo"
                      name="amount"
                      rules="required|min:1|max:8"
                      :label="$t('OpenAccount.DepositAmount')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3" :label="$t('OpenAccount.DepositAmount')">
                        <b-form-input
                          v-model="form.amount"
                          type="text"
                          :placeholder="$t('OpenAccount.DepositAmount')"
                          v-maska="{ mask: 'XZ*', tokens: { X: { pattern: /[1-9]/ }, Z: { pattern: /[0-9]/ } } }"
                          :class="{ 'is-invalid': errors.length }"
                          v-bind="field"
                        />

                        <ErrorMessage name="amount" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="password"
                      rules="required|min:8"
                      :label="$t('Profile.Settings.TradePassword')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group
                        class="mb-3 text-muted"
                        :label="$t('OpenAccount.AccountPassword')"
                        label-for="new_trade_password"
                      >
                        <b-input-group>
                          <b-form-input
                            id="trpassword"
                            v-model="form.password"
                            :type="passwordFields.password"
                            class="form-control-merge"
                            :class="{ 'is-invalid': errors.length }"
                            v-bind="field"
                          />
                          <b-input-group-append is-text :class="{ 'is-invalid': errors.length }">
                            <i
                              class="mdi cursor-pointer text-muted font-size-14"
                              :class="[passwordIconClasses.password]"
                              @click="togglePasswordVisibility('password')"
                            ></i>
                          </b-input-group-append>
                        </b-input-group>

                        <ErrorMessage name="password" class="invalid-feedback d-block" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="password_confirmation"
                      rules="required|confirmed:@password"
                      :label="$t('OpenAccount.AccountPasswordRepeat')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group
                        class="mb-3 text-muted"
                        :label="$t('OpenAccount.AccountPasswordRepeat')"
                        label-for="password_confirmation"
                      >
                        <b-input-group>
                          <b-form-input
                            id="password_confirmation"
                            v-model="form.password_confirmation"
                            :type="passwordFields.password_confirmation"
                            class="form-control-merge"
                            :class="{ 'is-invalid': errors.length }"
                            v-bind="field"
                          />
                          <b-input-group-append is-text :class="{ 'is-invalid': errors.length }">
                            <i
                              class="mdi cursor-pointer text-muted font-size-14"
                              :class="[passwordIconClasses.password_confirmation]"
                              @click="togglePasswordVisibility('password_confirmation')"
                            ></i>
                          </b-input-group-append>
                        </b-input-group>

                        <ErrorMessage name="password_confirmation" class="invalid-feedback d-block" />
                      </b-form-group>
                    </Field>

                    <div class="text-center mt-4">
                      <button type="submit" class="btn btn-dark btn-sm w-md">{{ $t('OpenAccount.SubmitBtn') }}</button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4 col-sm-3 col-12 px-4 px-sm-2">
            <div class="form-title">{{ $t('OpenAccount.TradingConditions') }}</div>

            <div class="mt-3">
              <div class="text-muted mb-2">{{ $t('OpenAccount.Currency') }}</div>
              <div class="mb-5">
                {{ cyrrenciesList?.find((c) => c.value === form.currency_id)?.text }}
              </div>

              <div class="text-muted mb-2">{{ $t('OpenAccount.MinimalDeposit') }}</div>
              <div class="mb-5">0,01</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </b-overlay>
</template>

<style scoped lang="scss">
.type-select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  &-item {
    width: 48%;
    padding: 26px 22px;
    display: flex;
    align-items: center;
    border: 2px solid #f6f6f6;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;

    @media (max-width: 576px) {
      width: 100%;
    }

    &:hover {
      box-shadow: 0 10px 20px rgba(18, 38, 63, 0.1);
    }

    &.active {
      border-width: 2px;

      &.real {
        border-color: var(--bs-success);
      }
      &.demo {
        border-color: var(--bs-primary);
      }
    }
  }
}
</style>
